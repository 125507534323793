import React, { useEffect, useState } from "react";

import { getDoubtsFirebase } from "../Utils/firebasePosts";

export const PostContext = React.createContext();

export const PostStorage = ({ children }) => {
  const [doubtsList, setDoubtsList] = useState([]);

  // POPULATE Doubts
  useEffect(() => {
    if (doubtsList.length === 0) {
      getDoubtsFirebase().then(function (result) {
        if (result) {
          setDoubtsList(result.doubtsPor);
        } else {
          setDoubtsList([]);
        }
      });
    }
  }, [doubtsList.length]);

  return (
    <PostContext.Provider
      value={{
        doubtsList,
        setDoubtsList,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
