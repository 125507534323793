import React from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import "./theme.css";
import { Home } from "./Components/Home";
import { Doubts } from "./Components/Doubts";
import { PostStorage } from "./Context/PostContext";

function App() {
  return (
    <PostStorage>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:slug" element={<Doubts />} />
      </Routes>
    </PostStorage>
  );
}

export default App;
