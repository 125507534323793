import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";

import css from "./Doubts.module.css";
import { Header } from "../Layout/Header";
import { getPostBySlugFirebase } from "../Utils/firebasePosts";
import { Head } from "../Utils/Head";

export const Doubts = () => {
  const params = useParams();
  const [post, setPost] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPostBySlugFirebase(params.slug).then(function (result) {
      if (result) {
        setPost(result);
        setLoading(true);
      } else {
        setPost("não encontrado");
      }
    });
  }, [params.slug]);

  if (!loading) {
    return <div>Post não encontrado</div>;
  } else {
    return (
      <>
        <Head title={post.title} description={post.title + " - Vortari"} />
        <Header />
        <div className={css.container}>
          <div></div>
          <div>
            <h1>{post.title}</h1>
            {HTMLReactParser(post.content)}
          </div>
          <div></div>
        </div>
      </>
    );
  }
};
