import React, { useContext } from "react";
import { Link } from "react-router-dom";

import css from "./Home.module.css";
import { PostContext } from "../Context/PostContext";
import { Head } from "../Utils/Head";
import { Header } from "../Layout/Header";

export const Home = () => {
  const { doubtsList } = useContext(PostContext);

  return (
    <>
      <Head title="Home" description="Home - Vortari" />
      <Header />
      <div className={css.container}>
        <div></div>
        <div>
          <h1>Principais Duvidas</h1>
          <ul>
            {doubtsList.map((doubt, i) => (
              <li key={i}>
                <Link to={"/" + doubt.slug}>{doubt.title}</Link>
              </li>
            ))}
          </ul>
        </div>
        {/* <h2>Frase do dia</h2> */}
        <div></div>
      </div>
    </>
  );
};
